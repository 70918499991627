.meeting-notes-container {
    background-color: #F1F1F1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topic-date-time-groups-container,
.attachments-container,
.meeting-notes-main-container {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
    max-width: 60vw;
    min-height: fit-content;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

    .topic-date-time-groups-container:hover,
    .attachments-container:hover,
    .meeting-notes-main-container:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

.agenda-container-wrapper,
.action-items-container-wrapper,
.discussion-notes-container-wrapper,
.decisions-made-container-wrapper {
    display: flex;
    justify-content: center;
    width: 60vw;
    padding-bottom: 15px;
}

.agenda-container-wrapper,
.action-items-container-wrapper,
.discussion-notes-container-wrapper {
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

    .agenda-container-wrapper:hover,
    .action-items-container-wrapper:hover,
    .discussion-notes-container-wrapper:hover,
    .decisions-made-container-wrapper:hover {
        background-color: #e0e0e0;
        color: #0056b3;
    }

    .agenda-container-wrapper:hover {
        border-radius: 8px 8px 0 0;
    }

    .decisions-made-container-wrapper:hover {
        border-radius: 0 0 8px 8px;
    }

    .date-time-groups-container,
    .topic-description-container,
    .attachments-container,
    .agenda-container,
    .action-items-container,
    .discussion-notes-container,
    .decisions-made-container {
        display: flex;
        flex-direction: column;
        width: 95%;
    }

.date-time-groups-container,
.attachments-container,
.attachments-header-container {
    justify-content: flex-start;
    width: 95%;
    height: fit-content;
    font-weight: 600;
    font-size: medium;
}

.date-time-groups-headers,
.date-time-groups-field-values,
.add-view-attachments-links,
.agenda-header-edit-delete-container,
.action-items-header-edit-delete-container,
.discussion-notes-header-edit-delete-container,
.decisions-made-header-edit-delete-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.date-time-header,
.attachments-header,
.date-time {
    display: flex;
    justify-content: flex-start;
    width: 350px;
}

.agenda-header-edit-delete-container,
.action-items-header-edit-delete-container,
.discussion-notes-header-edit-delete-container,
.decisions-made-header-edit-delete-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.agenda-content-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.agenda-save-cancel-buttons-container,
.action-items-save-cancel-buttons-container,
.discussion-notes-save-cancel-buttons-container,
.decisions-made-save-cancel-buttons-container,
.add-view-attachments-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .add-view-attachments-links {
        margin-bottom: 25px;
    }

    .agenda-delete-button,
    .action-items-delete-button,
    .discussion-notes-delete-button,
    .decisions-made-delete-button {
        display: none;
        background: none;
        border: none;
        cursor: pointer;
    }

.agenda-container-wrapper:hover .agenda-delete-button,
.action-items-container-wrapper:hover .action-items-delete-button,
.discussion-notes-container-wrapper:hover .discussion-notes-delete-button,
.decisions-made-container-wrapper:hover .decisions-made-delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
}

.agenda-container-wrapper:hover .agenda-delete-button,
.action-items-container-wrapper:hover .action-items-delete-button,
.discussion-notes-container-wrapper:hover .discussion-notes-delete-button,
.decisions-made-container-wrapper:hover .decisions-made-delete-button {
    background: red;
    color: white;
}

.agenda-save-button,
.agenda-cancel-button,
.action-items-save-button,
.action-items-cancel-button,
.discussion-notes-save-button,
.discussion-notes-cancel-button,
.decisions-made-save-button,
.decisions-made-cancel-button {
    display: inline-block;
    cursor: pointer;
}

.agenda-container-wrapper:hover .agenda-save-button,
.agenda-container-wrapper:hover .agenda-cancel-button,
.action-items-container-wrapper:hover .action-items-save-button,
.action-items-container-wrapper:hover .action-items-cancel-button,
.discussion-notes-container-wrapper:hover .discussion-notes-save-button,
.discussion-notes-container-wrapper:hover .discussion-notes-cancel-button,
.decisions-made-container-wrapper:hover .decisions-made-save-button,
.decisions-made-container-wrapper:hover .decisions-made-cancel-button {
    display: inline-block;
}

.textarea-agenda,
.textarea-action-items,
.textarea-discussion-notes,
.textarea-decisions-made {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 5rem;
    opacity: 0.75;
}

.textarea-agenda-editing,
.textarea-action-items-editing,
.textarea-discussion-notes-editing,
.textarea-decisions-made-editing {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 5rem;
    opacity: 1;
    font-weight: 900;
}

.agenda-save-button-hidden,
.agenda-cancel-button-hidden,
.action-items-save-button-hidden,
.action-items-cancel-button-hidden,
.discussion-notes-save-button-hidden,
.discussion-notes-cancel-button-hidden,
.decisions-made-save-button-hidden,
.decisions-made-cancel-button-hidden {
    display: none;
}

.send-email-button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.send-email-button {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 50px;
}

    .send-email-button:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

.topic-header-description,
.upcoming-meetings-topics-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    height: fit-content;
    font-weight: 600;
    font-size: medium;
}

.add-view-attachments-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 95%;
    height: fit-content;
    font-weight: 600;
    font-size: medium;
}

.saveCancelButton {
    margin-top: 20px;
    margin-bottom: 0px;
}

.decisions-made-container {
    padding-bottom: 15px;
}