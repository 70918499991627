.cu-select-container{
    margin: 1rem;
}

#cu-template-select{
    margin: .5rem;
}

.cu-file-input{
    border: thin solid black;
    margin: .25rem 0;
}

.template-groups-container{
    text-align: start;
    margin-bottom: 2rem;
}



/* buttons */

.cu-buttons-container {
    display: flex;
    flex-direction: column;
}


/* gray buttons (default) */
.cu-button{
  background-color: rgb(204, 204, 204);
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: .5rem 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.cu-button:hover,
.cu-button:focus {
  background-color: rgb(170, 170, 170);
}

.cu-button:focus {
  box-shadow: 0 0 0 4px rgba(204, 204, 204, .15);
}

.cu-button:active {
  background-color: rgb(136, 136, 136);
  box-shadow: none;
}

/* red buttons */
.cu-button.red{
    background-color: rgb(255, 70, 70);
    color: white;
}

.cu-button.red:hover,
.cu-button.red:focus {
  background-color: rgb(204, 85, 85);
}

.cu-button.red:focus {
  box-shadow: 0 0 0 4px rgba(255, 102, 102, .15);
}

.cu-button.red:active {
  background-color: rgb(189, 68, 68);
  box-shadow: none;
}


/* blue buttons */
.cu-button.blue{
    background-color: rgb(0, 149, 255);
    color: white;
}

.cu-button.blue:hover,
.cu-button.blue:focus {
  background-color: rgb(0, 119, 204);
}

.cu-button.blue:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.cu-button.blue:active {
  background-color: rgb(0, 100, 189);
  box-shadow: none;
}


/* green buttons */
.cu-button.green{
    background-color: rgb(102, 255, 102);
}

.cu-button.green:hover,
.cu-button.green:focus {
  background-color: rgb(85, 204, 85);
}

.cu-button.green:focus {
  box-shadow: 0 0 0 4px rgba(102, 255, 102, .15);
}

.cu-button.green:active {
  background-color: rgb(68, 189, 68);
  box-shadow: none;
}

