.tasks-gauge-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

    .tasks-gauge-container h2 {
        text-align: center;
        font-size: 1.5em;
        font-weight: 700;
        color: #333;
    }

.gauge-progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}

.gauge-progress-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: 85%;
    border-radius: 50%;
    padding: 10px;
    background: conic-gradient( blue var(--percentage-degree, 0deg), grey var(--percentage-degree, 0deg) 360deg );
}

.gauge-inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 95%;
    border-radius: 50%;
    background: white;
}

.gauge-percentage {
    font-size: 1.5em;
    font-weight: bold;
}

/* Additional styles for completion and remaining tasks, similar to TasksView.css */
.gauge-completed-tasks {
    color: green;
}

.gauge-remaining-tasks {
    color: red;
}

img {
    width: 40vw;
    height: auto;
}