.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    max-width: 300px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-input,
.modal-footer {
    margin-bottom: 13px;
}

.modal-input {
    padding: 10px;
    border-radius: 5px;
}

.modal-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btn-modal {
    margin-bottom: 5px;
    padding: 7px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-modal:hover {
    opacity: 0.8;
}

.dropdown, .checkbox {
    margin-left: 1rem;
}

.checkbox-container{
    margin-bottom: 1rem;
}

.attachment-container{
    display: flex;
}

.delete-attachment-button {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: rgb(255, 0, 0);
    color: white;
    border: none;
    cursor: pointer;
    margin-left: 2rem;
}

.delete-attachment-button:hover {
    background-color: rgb(197, 13, 0);
}