.subtasks-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.subtasks-view {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #F8F8F8;
    border-radius: 10px;
    max-width: 58.75vw;
    width: 80vw;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

    .subtasks-view:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

.subtasks-header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subtasks-edit-task-button,
.subtasks-delete-task-button {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

.subtasks-header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subtasks-header:hover .subtasks-edit-task-button,
.subtasks-header:hover .subtasks-delete-task-button {
    display: inline-block;
}

.subtasks-header:hover .subtasks-delete-task-button {
    background: red;
    color: white;
}

.subtask-task-name h2 {
/*    color: #333;*/
    color: #0146ea;
}

.subtasks-completion-percentage {
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 70%;
    margin: 25px 0;
    margin-top: 5px;
    text-align: center;
}

    .subtasks-completion-percentage p {
        margin-top: 0px;
        font-size: 1.25em; /* Larger font size */
        color: #333; /* Darker color for better visibility */
    }

.subtasks-progress-bar {
    background-color: #e0e0e0; /* Light grey background */
    border-radius: 5px;
    overflow: hidden;
}

.subtasks-progress {
    height: 15px; /* Height of the progress bar */
    background-color: #4CAF50; /* Green color for progress */
    border-radius: 5px;
    transition: width 0.3s ease-in-out; /* Smooth transition for width changes */
}

.subtasks-totals {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

.subtasks-total-subtasks {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.subtasks-remaining {
    width: 33%;
    display: flex;
    justify-content: center;
}

.subtasks-completed {
    width: 33%;
    display: flex;
    justify-content: flex-end;
}

.completed-subtasks {
    color: green;
}

.remaining-subtasks {
    color: red;
}

.subtasks-totals h4,
.subtasks-totals p {
    margin: 0;
    font-size: 0.875em;
}

.subtasks-fields {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: -15px;
    padding-bottom: 0;
}

.subtasks-fields h4 {
    font-size: 0.875em;
    color: #666;

}

.subtasks-fields-name {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.subtasks-fields-due-date {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.subtasks-fields-completion-status {
    width: 33%;
    display: flex;
    justify-content: flex-end;
}

.subtasks-body {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 4px;
}

.subtask-records {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
    position: relative;
    transition: background-color 0.3s ease;
}

    .subtask-records:hover {
        background: #e8e8e8;
    }

.subtask-completed-field-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: small;
    font-weight: 500;
    margin-top: -15px;
    margin-bottom: -15px;
}

.subtask-data,
.subtask-description {
    display: flex;
    align-items: center;
}

.subtask-data {
    width: 100%;
}

.subtask-description {
    flex-direction: column;
    width: 100%;
}

.subtask-description-text {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

    .subtask-name h4,
    .subtask-description-text p {
        margin: 0;
        font-size: 0.875em;
        color: #333;
    }

.subtask-description-view-attachments {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.subtask-due-date p {
    font-size: 0.875em;
    color: #666;
}

.subtask-hover-actions {
    justify-content: center;
    gap: 7px; /* Space between buttons */
    position: absolute;
    right: 70px; /* Align to the left edge of subtask record */
    top: 46px;
    transform: translateY(-50%);
    display: flex;
    margin-left: 5px;
}

.subtasks-edit-subtask-button,
.subtasks-delete-subtask-button,
.subtasks-add-attachment-button {
    display: none; /* Hide buttons by default */
    background: none;
    border: none;
    cursor: pointer;
}

.subtask-records:hover .subtasks-edit-subtask-button,
.subtask-records:hover .subtasks-delete-subtask-button,
.subtask-records:hover .subtasks-add-attachment-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
}

.subtask-records:hover .subtasks-delete-subtask-button {
    background: red;
    color: white;
}

.subtasks-edit-subtask-button:hover::before,
.subtasks-delete-subtask-button:hover::before,
.subtasks-add-attachment-button:hover::before {
    content: attr(aria-label);
    position: absolute;
    top: -20px;
    transform: translateX(-50%);
    background: black;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
}

.subtask-checkbox {
    margin-left: auto; /* Ensures checkbox is aligned to the right */
}

.subtask-view-attachment {
    border: none;
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.875em;
    cursor: pointer;
}

    .subtask-view-attachment:hover {
        background-color: #45a049;
    }

@media (max-width: 768px) {
    /* Responsive styles can be added here */
}

.subtask-name,
.subtask-due-date,
.subtask-data-right {
    display: flex;
    width: 33%;
}

.subtasks-edit-task-name,
.subtask-task-name,
.subtask-delete-task {
    width: 33%;
}

.subtasks-edit-task-name {
    display: flex;
    justify-content: flex-start;
}

.subtask-delete-task {
    display: flex;
    justify-content: flex-end;
}

.subtask-footer {
    width: 100%;
    display: flex;
    padding: 10px;
}

.no-subtasks-add-subtask {
    padding-top: 15px;
}

.subtasks-edit-task-button:hover::before {
    content: attr(aria-label);
    position: absolute;
    top: -20px;
    transform: translateX(-50%);
    background: black;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
}


.attachment-link{
    margin-bottom: 1rem;
}

.attachment-link:hover{
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.no-subtasks-add-subtask-button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}