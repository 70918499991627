.upcoming-meetings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    width: 100%;
}

.upcoming-meetings-table, .prior-meetings-container {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 60vw;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

    .upcoming-meetings-table:hover, .prior-meetings-container:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

.upcoming-meetings-header, .prior-meetings-header {
    display: flex;
    justify-content: center;
    width: 100%;
}

.upcoming-meetings-date-time-header,
.upcoming-meetings-groups-header,
.upcoming-meetings-topics-header,
.prior-meetings-date-time-header,
.prior-meetings-groups-header,
.prior-meetings-topics-header {
    display: flex;
    justify-content: flex-start;
    width: 33.33%;
    height: 35px;
    font-weight: 600;
    font-size: medium;
}

.upcoming-meetings-columns, .prior-meetings-columns {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.navlink {
    text-decoration: none;
}

.upcoming-meetings-record, .prior-meetings-record {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
}

.upcoming-meetings-date-time,
.upcoming-meetings-groups,
.upcoming-meetings-topics,
.prior-meetings-date-time,
.prior-meetings-groups,
.prior-meetings-topics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 33.33%;
    padding: 0 5px;
}

.upcoming-meetings-record, .prior-meetings-record {
    background-color: white;
    display: flex;
    border: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

    .upcoming-meetings-record:hover, .prior-meetings-record:hover {
        background-color: #e0e0e0;
        color: #0056b3;
    }

    .upcoming-meetings-record button, .prior-meetings-record button {
        display: none;
        background: none;
        border: none;
        cursor: pointer;
    }

    .upcoming-meetings-record:hover button, .prior-meetings-record:hover button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        height: 20px;
        width: 20px;
    }

.sales-admin-button:hover button:nth-child(2) {
    background: red;
    color: white;
}

.upcoming-meetings-record-buttons, .prior-meetings-record-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: none;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.add-meeting-button {
    margin: 15px 0;
}

    .add-meeting-button:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

.meeting-detail .meeting-label {
    display: none;
    font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
    .upcoming-meetings-header,
    .prior-meetings-header {
        display: none;
    }

    .upcoming-meetings-date-time-header,
    .upcoming-meetings-groups-header,
    .upcoming-meetings-topics-header,
    .prior-meetings-date-time-header,
    .prior-meetings-groups-header,
    .prior-meetings-topics-header {
        width: 100%;
    }

    .upcoming-meetings-record,
    .prior-meetings-record,
    .upcoming-meetings-date-time,
    .upcoming-meetings-groups,
    .upcoming-meetings-topics,
    .prior-meetings-date-time,
    .prior-meetings-groups,
    .prior-meetings-topics {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .upcoming-meetings-record-buttons,
    .prior-meetings-record-buttons {
        justify-content: flex-start;
        width: 100%;
    }

    .meeting-detail .meeting-label {
        display: inline;
        margin-right: 5px;
    }
}
