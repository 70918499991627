table {
    margin: 2rem auto;
    color: #222;
    background: white;
    border: 1px solid gray;
    font-size: 1.2rem;
    border-collapse: collapse;
}

th {
    color: #777;
    background: #eee
}

th,
td {
    padding: 1rem;
    border: 1px solid lightgray;
}

.manageacct-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.manageacct-button {
    padding: .5rem;
    margin: 1rem;
}

.cu-assignment-container,
.cu-dropdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cu-list {
    font-family: inherit;
    font-size: 1rem;
    overflow-y: scroll;
    height: 5rem;
    width: 10rem;
    text-align: center;
}

/*HideScrollbar*/
.cu-list::-webkit-scrollbar {
    width: 0 !important
}

.cu-list {
    overflow: -moz-scrollbars-none;
}

.cu-list {
    -ms-overflow-style: none;
}

.cu-assignment-container {
    flex-direction: row;
}

.cu-dropdown-container {
    flex-direction: column;
    margin-left: 1rem;
}

.cu-assignment-dropdown {
    margin-top: 1rem;
}

.cu-assignment-button {
    margin: 1rem;
}

.user-buttons-container {
    display: flex;
    flex-direction: column;
}

.user-button {
    margin: .5rem 0;
}




/* role definitions modal styles */
/* need to overwrite width attributes of modal-content class */
.role-modal-container{
  width: fit-content;
  min-width: 600px;
  max-width: 1000px;
}

.role-description-column{
  min-width: 500px;
}


/* gray buttons (default) */
.manageacct-button, .user-button{
    background-color: rgb(204, 204, 204);
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 1;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
  }
  
  .manageacct-button:hover,
  .manageacct-button:focus,
  .user-button:hover,
  .user-button:focus {
    background-color: rgb(170, 170, 170);
  }
  
  .manageacct-button:focus, .user-button:focus {
    box-shadow: 0 0 0 4px rgba(204, 204, 204, .15);
  }
  
  .manageacct-button:active, .user-button:active {
    background-color: rgb(136, 136, 136);
    box-shadow: none;
  }
  
  
  /* red buttons */
  .manageacct-button.red, .user-button.red {
      background-color: rgb(255, 70, 70);
      color: white;
  }
  
  .manageacct-button.red:hover,
  .manageacct-button.red:focus,
  .user-button.red:hover,
  .user-button.red:focus {
    background-color: rgb(204, 85, 85);
  }
  
  .manageacct-button.red:focus,
  .user-button.red:focus {
    box-shadow: 0 0 0 4px rgba(255, 102, 102, .15);
  }
  
  .manageacct-button.red:active,
  .user-button.red:active {
    background-color: rgb(189, 68, 68);
  }
  
  
  /* blue buttons */
  .manageacct-button.blue, .user-button.blue{
      background-color: rgb(0, 149, 255);
      color: white;
  }
  
  .manageacct-button.blue:hover,
  .manageacct-button.blue:focus,
  .user-button.blue:hover,
  .user-button.blue:focus {
    background-color: rgb(0, 119, 204);
  }
  
  .manageacct-button.blue:focus,
  .user-button.blue:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  }
  
  .manageacct-button.blue:active,
  .user-button.blue:active  {
    background-color: rgb(0, 100, 189);
  }
  
  
  /* green buttons */
  .manageacct-button.green, .user-button.green{
      background-color: rgb(102, 230, 102);
  }
  
  .manageacct-button.green:hover,
  .manageacct-button.green:focus,
  .user-button.green:hover,
  .user-button.green:focus {
    background-color: rgb(85, 204, 85);
  }
  
  .manageacct-button.green:focus,
  .user-button.green:focus {
    box-shadow: 0 0 0 4px rgba(102, 230, 102, .15);
  }
  
  .manageacct-button.green:active,
  .user-button.green:active {
    background-color: rgb(68, 189, 68);
  }

