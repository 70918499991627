:root {
    --background: #F1F1F1;
    --primary: #F8F8F8;
    --secondary: blue;
    --pageButtons: #2a4c9b;
    --pageButtonsText: white;
    --hover: blue;
    --links: #2a4c9b;
    --border: #2a4c9b;
    background-color: var(--background);
}

/* STYLES SPECIFIC TO PRIVACY POLICY PAGE */

.policy-container {
    padding: 2rem;
    display: inline-block;
    text-align: left;
}

/* STYLES SPECIFIC TO RESETPASSWORD PAGE */

.requirements-initial,
.requirement-met,
.requirement-missing {
    font-size: 16px;
    text-align: center;
    list-style: none;
}

.requirement-met {
    color: green;
    text-decoration: line-through;
}

.requirement-missing {
    color: red;
    font-weight: 600;
}

/* STYLES FOR LOGIN, FORGOTPASSWORD, FORGOTUSERNAME, AND RESETPASSWORD PAGES */

.page {
    margin: 20px auto;
    width: 90%;
    min-width: 300px;
    max-width: 600px;
    font-size: 20px;
}

.logo {
    width: 400px;
}

.page-input {
    display: block;
    margin: 0 auto 0px auto;
    height: 25px;
    width: 80%;
    max-width: 300px;
    font-size: 20px;
    padding: 12px;
    border: 2px solid;
    border-radius: 8px;
    transition-duration: 0.4s;
    text-align: left;
    background-color: rgb(232, 240, 254);
}

    .page-input:focus {
        outline: none;
        box-shadow: 0px 0px 4px var(--primary);
    }

.page-button {
    display: block;
    margin: 20px auto;
    width: 100%;
    max-width: 325px;
    padding: 12px;
    border: 2px hidden;
    border-radius: 8px;
    font-size: 18px;
    background-color: var(--pageButtons);
    color: var(--pageButtonsText);
    transition-duration: 0.4s;
    cursor: pointer;
}

    .page-button:hover {
        background-color: var(--hover);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .page-button:disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }

        .page-button:disabled:hover {
            background-color: grey;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

.page-text {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
    display: block;
}

label.page-text {
    margin-top: 25px;
    margin-bottom: 15px;
}

.page-link {
    text-align: center;
    margin: 5px 0;
    color: var(--links);
    transition-duration: 0.4s;
    text-decoration: none;
}

    .page-link:hover {
        color: var(--secondary);
        cursor: pointer;
    }

.page-form {
    margin-top: 40px;
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 0 40px 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--primary);
}

.page-inner-form {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}
