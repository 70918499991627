.material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 24
}


.critical-tasks-container {
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100vw;
    padding: 20px 20px 35px 20px;
    margin: 40px 0px 40px 0px;
    border-top: .1rem solid rgba(0, 0, 0, 0.1);
    border-bottom: .1rem solid rgba(0, 0, 0, 0.1);
}

.critical-tasks-list {
/*    background-color: lightslategrey;*/
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 62%;
    max-width: 100%;
}

.dropdown {
    display: flex;
    flex-direction: column;
}
    
.dropdown-toggle {
/*    background-color: lightsalmon;*/
    display: flex;
    align-items: center;    
}

    .dropdown-toggle button:hover {
        color: blue;
        cursor: pointer;
    }

.tasks-dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
}

    .tasks-dropdown-toggle button {
        background: none;
        display: flex;
        justify-content: center;
        width: 30px;
        border: none;
        border-color: red;
    }

        .tasks-dropdown-toggle button:hover {
            color: blue;
            cursor: pointer;
        }

.underline {
    display: flex;
    width: 92%;
    max-width: 92%;
    border-bottom: 3px solid black; /* Separator line when active */
    padding-top: 4px;
    padding-bottom: 4px;
}

.critical-groups {
/*    background-color: lightyellow;*/
    display: flex;
    flex-direction: column;
    font-size: larger;
    font-weight: 700;
}

    .critical-groups h3 {
        display: flex;
        /*    background-color: lightblue;*/
        border-width: 100%;
        width: 100%;
        font-size: larger;
        font-weight: 500;
        color: #0083d6;
        margin: 10px;
    }

.critical-tasks {
    /*        background-color: lightseagreen;*/
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 92%;
    margin-left: 40px;
    padding-bottom: 10px;
    font-size: large;
    font-weight: 600;
}

    .critical-tasks p {
        color: black;
    }

.critical-subtasks {
    /*    background-color: darkorange;*/
    display: flex;
    flex-direction: column;
    padding: 0px 23px 0px 23px;
}

.critical-subtasks-items {
    /*    background-color: mediumpurple;*/
    display: flex;
    justify-content: space-between;
    margin: 10px;
    font-size: large;
    font-weight: 500;
}

.critical-subtask-date {
    display: flex;
    justify-content: space-between;
    width: 195px;
}

.critical-subtasks a {
    color: #0083d6;
    text-decoration: none;
    border-bottom: .1rem solid rgba(0, 0, 0, 0.1);
}

    .critical-subtasks a:hover {
        background-color: #e0e0e0;
        color: #0056b3;
    }

.due-date {
    color: red;
}

.date-div {
    color: #0083d6;
}

.critical-tasks-button {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: .18rem solid black;
    border-color: red;
    border-radius: 50%;
    margin-right: 10px;
}

.email-button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}