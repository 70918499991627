.upcoming-meeting-text, .meeting-schedule-button {
    font-size: large;
    font-weight: bold;
    margin: .25rem;
}

.upcoming-meeting-text.larger{
    font-size: larger;
}

.upcoming-meeting-header{
    margin: 2rem 0 .5rem 0;
}

.meeting-schedule-button{
    margin-top: 1rem;
    padding: .5rem;
}

    .meeting-schedule-button:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }


/* UNCOMMENT TO CHANGE VIEW TO HORIZONTAL */
/* .upcoming-meeting-container, .meeting-schedule-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}  */
