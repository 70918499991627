.group-card {
    background-color: #F8F8F8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    position: relative;
    transition: box-shadow 0.3s ease;
}

    .group-card:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

.nav-link {
    text-decoration: none;
    font-size: larger;
    font-weight: 600;
    color: black;
}

    .nav-link:hover {
        color: #0146ea;
    }

    .nav-link:hover p {
        color: 
    }

    .nav-link:active {
        color: #0146ea;
    }

.group-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    max-width: 60vw;
    margin: auto;
    justify-content: center;
}

.add-group-button {
    margin: 1rem;
    cursor: pointer;
}

    .add-group-button:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    display: none;
}

.edit-button {
    position: absolute;
    top: 5px;
    left: 5px;
    background: none;
    color: white;
    border: none;
    cursor: pointer;
    display: none;
}

.group-card.active {
    color: blue;
}

.group-card:hover .delete-button,
.group-card:hover .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
}

.delete-button:hover::before {
    content: 'Delete';
    position: absolute;
    top: -20px;
    right: 0;
    background: black;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.edit-button:hover::before {
    content: 'Edit';
    position: absolute;
    top: -20px;
    right: 0;
    background: black;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}
