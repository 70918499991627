.timeline-container {
    display: flex;
    justify-content: center;
    padding-top: 35px;
    width: 100%;
    position: relative;
}

.card-container {
    display: flex;
    justify-content: center;
    width: 60%;
    max-width: 60%;
    position: relative;
}

.card {
    background-color: darkgrey; /* Default gray background */
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; /* Default text color */
    font-family: Arial, sans-serif;
    font-size: 1em;
    clip-path: polygon(16% 50%, 5% 0%, 80% 0%, 90% 50%, 80% 100%, 5% 100%);
    transition: all 0.25s ease-in-out;
    opacity: 0.85;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    margin-left: -6px; /* Overlap between cards */
    position: relative; /* Needed for z-index */
}

    .card:hover {
        z-index: 3; /* Brings hovered card to the front layer */
        font-weight: bold;
        color: #0A7FE9; /* Changes text color to blue for the hovered card */
    }

.card-adjacent {
    z-index: 2; /* Adjacent cards slightly behind the hovered card */
}

.card-farther {
    z-index: 1; /* All other cards in the farthest back layer */
}

.card-today {
    background-color: #00BF63 !important; /* Green background for today or past dates */
}

.card-next {
    background-color: #F11228 !important; /* Red background for the next meeting date */
}

.card-following {
    background-color: #FF9A2B !important; /* Yellow background for the following meeting date */
}

.card-container .card:first-child {
    margin-left: 0; /* No overlap for the first card */
}

.card-date {
    padding-right: 3px;
    width: 60%;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .card {
        height: 45px;
        font-size: 0.9em;
    }

    .card-date {
        font-size: 0.8em;
    }

    .dialog-box {
        max-width: 30%;
    }
}

@media (max-width: 992px) {
    .card {
        height: 40px;
        font-size: 0.8em;
    }

    .card-date {
/*        padding-right: 1px;*/
        font-size: 0.7em;
    }

    .dialog-box {
        max-width: 40%;
    }
}

@media (max-width: 768px) {
    .card {
        height: 35px;
        font-size: 0.7em;
    }

    .card-date {
/*        padding-right: 1px;*/
        font-size: 0.6em;
    }

    .dialog-box {
        max-width: 50%;
    }
}

@media (max-width: 576px) {
    .card-container {
        width: 80%;
        max-width: 80%;
    }

    .card {
        height: 30px;
        font-size: 0.6em;
    }

    .card-date {
        font-size: 0.5em;
    }

    .dialog-box {
        max-width: 70%;
    }
}

.dialog-container {
    position: absolute;
    top: 50%; /* Center vertically relative to timeline container */
    transform: translateY(-50%); /* Center vertically */
    pointer-events: none; /* Allows mouse events to pass through to cards */
    width: 100%; /* Full width to allow x positioning within entire container */
    z-index: 2001;
}

.dialog-box {
    pointer-events: auto; /* Re-enables pointer events on the dialog box */
    position: absolute;
    background-color: #B9B9B9;
    border: 0px solid black;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.75);
    display: block; /* Ensures it is visible when rendered */
    height: fit-content;
    z-index: 2000; /* Ensures it is above all other content */
}

    .dialog-box h3,
    .dialog-box p {
        margin: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .dialog-box h3 {
        margin: 0px;
        margin-bottom: -10px;
        text-decoration: underline;
    }

.dialog-content > p {
    text-align: left;
    color: white;
    font-weight: 600;
}

.dialog-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -10px;
}

.dialog-link a {
    text-decoration: none;
}

    .dialog-link a:hover {
        text-decoration: underline;
    }

    .dialog-link a:visited {
        color: purple;
        background-color: transparent;
        text-decoration: none;
    }

.close-button {
    position: absolute;
    top: -5px;
    right: -3px;
    border: none;
    background: none;
    color: red;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}

.active-hovered {
    z-index: 3;
    font-weight: bold;
    color: #0A7FE9;
    transform: scale(1.50);
}
