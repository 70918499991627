.task-card {
    background-color: #F8F8F8;
    /*    border: 1px solid #ccc;*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    position: relative;
    transition: box-shadow 0.3s ease;
    padding: 5px;
}

.task-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

    .task-card:hover .task-name {
        color: #0146ea;
    }

.task-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    max-width: 60vw;
    margin: auto;
    justify-content: center;
}

.add-task-button {
    margin: 1rem;
    cursor: pointer;
}

    .add-task-button:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

.task-card:hover .delete-button,
.task-card:hover .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
}

.delete-button:hover::before {
    content: 'Delete';
    position: absolute;
    top: -20px;
    right: 0;
    background: black;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.edit-button:hover::before {
    content: 'Edit';
    position: absolute;
    top: -20px;
    right: 0;
    background: black;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}


.task-name {
    cursor: pointer;
    font-weight: 600;
    font-size: larger;
    margin-bottom: -2px;
}

.task-name:hover {
    color: blue;
}

.task-name.active {
    color: #0146ea;
}

.task-description {
    font-size: 1.05em;
    font-weight: 400;
    margin-bottom: 0px;
}

.tasks-card-subtasks-completed {
    font-size: .94em;
    font-weight: 500;
    margin-bottom: 20px;
}

.tasks-header-container {
    display: flex;
    justify-content: center;
}

.tasks-header {
    display: flex;
    flex-direction: column;
    width: 60vw;
}

.tasks-completion-percentage {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 25px 0;
    margin-top: 5px;
    text-align: center;
}

.tasks-completion-percentage p {
    margin-top: 0px;
    font-size: 1.25em;
    /* Larger font size */
    font-weight: 600;
    color: #333;
    /* Darker color for better visibility */
}

.progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}

.progress-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: 85%;
    border-radius: 50%;
    padding: 10px;
    /* Creates the thick border effect */
}

.inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 95%;
    border-radius: 50%;
    background: white;
    overflow: hidden;
    /* Ensures the inner content doesn't spill out */
}

.percentage {
    font-size: 1.5em;
    font-weight: bold;
}



.tasks-totals {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

.tasks-total-tasks {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.tasks-remaining {
    width: 33%;
    display: flex;
    justify-content: center;
}

.tasks-totals {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

.tasks-total-tasks {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.tasks-remaining {
    width: 33%;
    display: flex;
    justify-content: center;
}

.tasks-completed {
    width: 33%;
    display: flex;
    justify-content: flex-end;
}

.completed-tasks {
    color: green;
}

.remaining-tasks {
    color: red;
}

.tasks-totals h4,
.tasks-totals p {
    margin: 0;
    font-size: 0.875em;
    font-weight: 700;
}

.numerator-no-completion {
    font-weight: 700;
    color: red;
}

.numerator-partial-completion {
    font-weight: 700;
    color: black;
}

.numerator-full-completion {
    font-weight: 700;
    color: green;
}

.denominator-completed {
    font-weight: 700;
    color: green;
}

.denominator-not-completed {
    font-weight: 700;
    color: red;
}

.no-subtask {
    font-weight: 700;
    color: red;
}

.task-name {
    color: black;
    text-decoration: none;
}

    .task-name p {
        color: black;
        font-size: 0.875em;
    }

    .task-name h2 {
        font-size: larger;
        font-weight: 700;
    }

    .task-name active {
        color: #0146ea;
    }