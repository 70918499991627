.navbar {
    background-color: #F8F8F8;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Small drop shadow */
}

.mobile-container {
    padding: 1rem;
}

.mobile-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

    .mobile-header button {
        position: absolute;
        left: 1rem; /* Keeps the hamburger menu aligned to the left */
    }

.menu-button {
    color: white;
    transition: transform 0.3s ease-in-out;
}

.rotate-90 {
    transform: rotate(90deg);
}

.logo-img {
    margin: 0 auto;
    width: 10rem;
    height: auto;
    max-height: 70px;
    cursor: pointer;
}

.mobile-menu {
    margin-top: 1rem;
}

.accordion-trigger {
    background-color: #3b82f6;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    transition: background-color 0.2s;
}

    .accordion-trigger:hover {
        background-color: #2563eb;
    }

.accordion-content {
    background-color: white;
    border-radius: 0 0 0.375rem 0.375rem;
    overflow: hidden;
}

.credit-union-button {
    width: 100%;
    text-align: left;
    padding: 0.5rem 1rem;
    transition: background-color 0.2s;
}

    .credit-union-button:hover {
        background-color: #bfdbfe;
    }

.manage-accounts-button {
    background-color: #3b82f6;
}

    .manage-accounts-button:hover {
        background-color: #2563eb;
    }

.logout-button {
    background-color: #ff4242;
}

    .logout-button:hover {
        background-color: #E42A2A;
    }

.manage-accounts-button,
.logout-button {
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.5rem 0;
}

.desktop-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.navigation-dropdown {
    position: relative;
}

.navigation-dropdown-trigger {
    background-color: #3b82f6;
    color: #2563EB;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .navigation-dropdown-trigger:hover {
        background-color: #2563eb;
    }

.navigation-dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border-radius: 0.375rem;
    overflow: hidden;
    z-index: 10;
    margin-top: 0.5rem;
}

.dropdown-item {
    width: 100%;
    text-align: left;
    padding: 0.5rem 1rem;
    transition: background-color 0.2s;
}

    .dropdown-item:hover {
        background-color: #bfdbfe;
    }

.welcome-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.welcome-message {
    color: black;
    padding: 0.5rem 1rem;
    white-space: nowrap; /* Prevents wrapping */
    overflow: visible; /* Ensures the message is not cut off */
    text-overflow: clip; /* Prevents ellipsis */
    width: auto; /* Adjusts width dynamically based on content */
    display: inline-block; /* Keeps the message inline */
}
